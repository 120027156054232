<template>
  <!-- Top Bar -->
  <div
    class="bg-[#ffffff] text-black px-2 flex md:flex-row md:items-center md:justify-between h-[60px] w-full roboto-light"
  >
    <div class="text-xs text-[#2A84EF] pt-3 p-1">
      <p>Hello..!</p>
      <p class="text-lg text-black text-start flex capitalize">
        {{ this.getUser ? this.getUser.display_name : "Null" }}
      </p>
    </div>
    <!-- Search Bar -->
    <div
      class="hidden md:flex gap-x-10 md:flex-row items-center gap-y-2 md:gap-y-0 md:gap-x-10"
    >
      <div class="relative items-center flex hidden">
        <input
          type="text"
          placeholder="Search..."
          class="bg-gray-100 text-gray-400 px-5 md:px-10 md:ml-10 py-1 rounded-md mr-2"
        />
        <img
          src="../assets/search.png"
          alt=""
          class="absolute left-0 md:left-14"
        />
      </div>
      <!-- Profile Icon -->
      <div class="flex gap-6 mr-4">
        <img src="../assets/Group_109.png" alt="" class="object-contain" />
        <button
          class="focus:outline-none hidden md:flex p-2 rounded-md bg-primary text-white h-10 w-10 flex items-center justify-center capitalize"
          @click="toggleButton"
        >
          <!-- <img src="../assets/Rectangle_313.png" class="object-contain" alt="" /> -->
          <span>{{
            this.getUser ? this.getUser.display_name.slice(0, 1) : "Null"
          }}</span>
        </button>
      </div>
      <div
        v-if="button_logout"
        class="flex flex-col absolute top-[62px] right-2 items-start bg-[#ffffff] border lato rounded-md"
      >
        <router-link
          v-if="getUser.permission == 'admin'"
          :to="{ name: 'AdminProfile' }"
          class="flex w-full text-center justify-center hover:bg-gray-100 px-5 py-2 text-sm border-b"
        >
          Profile
        </router-link>
        <router-link
          v-if="
            getUser.permission == 'external' || getUser.permission == 'employee'
          "
          :to="{ name: 'UserProfile' }"
          class="flex w-full text-center justify-center hover:bg-gray-100 px-5 py-2 text-sm border-b"
        >
          Profile
        </router-link>
        <router-link
          :to="{ name: 'UpdatePassword' }"
          class="flex w-full text-center justify-center hover:bg-gray-100 px-5 py-2 text-sm border-b"
        >
          Change Password
        </router-link>
        <button
          class="flex w-full text-center justify-center hover:bg-gray-100 px-5 py-2 text-sm"
          @click="logOut"
        >
          Logout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isSidebarOpen: true,
      isProfileMenuOpen: false,
      button_logout: false,
      // name: this.getUser.display_name
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    ...mapActions(["logout"]),
    logOut() {
      this.logout();
      this.$router.push({ name: "login" });
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleProfileMenu() {
      this.isProfileMenuOpen = !this.isProfileMenuOpen;
    },
    closeProfileMenu(event) {
      if (!event.target.closest(".relative")) {
        this.isProfileMenuOpen = false;
      }
    },
    toggleButton() {
      this.button_logout = !this.button_logout;
    },
  },
  mounted() {
    window.addEventListener("click", this.closeProfileMenu);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.closeProfileMenu);
  },
  created() {},
};
</script>
