<!-- PaymentComponent.vue -->
<template>
  <div
    class="max-w-[480px] mx-auto h-auto p-8 shadow-xl border border-gray-200 rounded-md"
  >
    <form @submit.prevent="handleSubmit" class="flex flex-col gap-2 h-full">
      <img
        src="@/assets/Logo.svg"
        alt="LIVEYfy LOGO"
        class="object-contain h-[40px]"
      />
      <h1 class="py-2">LIVEY TECHNOLOGIES LLC</h1>
      <div id="card-element"></div>
      <button
        type="submit"
        :disabled="processing"
        class="bg-sky-600 px-4 py-1 text-white rounded-md shadow-sm"
      >
        {{ processing ? "Processing…" : "Pay" }}
      </button>
    </form>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      stripe: null,
      card: null,
      clientSecret: "",
      processing: false,
      error: "",
      productDetails: {
        name: "LIVEYfy",
        description: "Organization Subscription Plan",
      },
      quantity: 1,
      userDetails: { name: "", email: "" },
      orderId: null,
      selected_plan: "",
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  created() {
    if (sessionStorage.getItem("plan_info")) {
      this.selected_plan = sessionStorage.getItem("plan_info");
    }
    this.userDetails.name = this.getUser.display_name;
    this.userDetails.email = this.getUser.email;
  },
  async mounted() {
    this.stripe = await loadStripe(
      `${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`
    );
    const elements = this.stripe.elements();
    this.card = elements.create("card");
    this.card.mount("#card-element");
    const response = await fetch(
      `${process.env.VUE_APP_API}/api/create-payment-intent/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          product_details: this.productDetails,
          quantity: this.quantity,
          user_details: this.userDetails,
          user_id: this.getUser.id,
          subscription_id: this.selected_plan,
        }),
      }
    );
    const data = await response.json();
    this.clientSecret = data.clientSecret;
    this.orderId = data.orderId;
  },
  methods: {
    async handleSubmit() {
      this.processing = true;

      const { error } = await this.stripe.confirmCardPayment(
        this.clientSecret,
        {
          payment_method: {
            card: this.card,
          },
        }
      );
      console.log("response:", error);
      if (error) {
        console.log("response if:", error);
        this.error = error.message;
        this.processing = false;
      } else {
        console.log("response else:", error);
        this.error = "";
        this.processing = false;
        await fetch(
          `${process.env.VUE_APP_API}/api/update-order-status/${this.orderId}/`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ status: "succeeded" }),
          }
        );

        this.$router.push({ name: "ThankYou" });
      }
    },
  },
};
</script>

<style>
#card-element {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}
</style>
