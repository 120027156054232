<template>
  <div
    class="bg-white md:h-screen flex flex-col justify-center items-center overflow-y-auto"
  >
    <form
      class="relative rounded-lg flex flex-col gap-y-2 md:gap-y-3 w-full md:w-[75%] max-w-[500px]"
      @submit.prevent="signUp"
    >
      <img
        @click="toHome"
        src="../assets/Logo.svg"
        alt="Logo"
        class="h-8 md:h-10"
      />

      <h1 class="text-xl md:text-3xl font-semibold text-center roboto-medium">
        Sign up
      </h1>
      <h2 class="text-sm md:text-xl text-center roboto-regular">
        Welcome to LIVEYfy!
      </h2>

      <div class="flex items-center p-1 border rounded-lg gap-x-2 roboto-light">
        <img src="../assets/User.svg" alt="UserName" class="h-5 w-5" />
        <input
          type="text"
          class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
          required
          v-model="fullname"
          placeholder="Full Name"
        />
      </div>

      <div class="flex items-center p-1 border rounded-lg gap-x-2 relative">
        <div
          ref="dropdownContent"
          class="h-5 w-5 text-sm bg-white cursor-pointer flex items-center justify-center"
          @click="is_country_code = !is_country_code"
        >
          <img
            :src="require(`@/assets/flags/${this.country_flag}.svg`)"
            class="h-4 w-4 object-contain"
            alt="india"
          />
        </div>
        <input
          type="text"
          class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
          required
          v-model="mobile"
          placeholder="Mobile Number"
        />
        <div
          v-if="is_country_code"
          class="absolute top-10 -left-1 bg-white z-50"
        >
          <div class="" ref="country_ref">
            <div
              class="max-h-[330px] overflow-y-auto shadow-xl rounded-sm p-2 border"
            >
              <div class="custom-select-trigger text-gray-300">
                Select an option
              </div>
              <div class="custom-options">
                <span
                  v-for="cc in c_code"
                  :key="cc"
                  class="flex gap-2 items-center justify-start text-xs hover:bg-gray-100 py-1.5 px-2"
                  @click="
                    (country_code = cc.dial_code),
                      (is_country_code = false),
                      (country_flag = cc.code)
                  "
                  ><img
                    :src="require(`@/assets/flags/${cc.code}.svg`)"
                    class="h-4 w-4 object-contain"
                    alt="Option 1"
                  />
                  {{ cc.name }}
                  <span class="text-gray-500">{{ cc.dial_code }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center p-1 border rounded-lg gap-x-2">
        <img src="../assets/mail.svg" alt="UserName" class="h-5 w-5" />
        <input
          type="email"
          class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
          required
          v-model="email"
          placeholder="Enter your Email"
        />
      </div>

      <div class="flex items-center p-1 border rounded-lg gap-x-2">
        <img src="../assets/lock.svg" alt="UserName" class="h-5 w-5" />
        <input
          type="password"
          class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
          required
          v-model="password"
          placeholder="Enter your Password"
          minlength="8"
        />
      </div>

      <div class="relative flex items-center p-1 border rounded-lg gap-x-2">
        <img src="../assets/lock.svg" alt="UserName" class="h-5 w-5" />
        <input
          type="password"
          class="text-sm placeholder-gray-500 rounded-md w-full py-2 focus:outline-none roboto-light"
          required
          v-model="confirmPassword"
          placeholder="Renter your Password"
          minlength="8"
        />
      </div>
      <p
        v-if="this.message.text != ''"
        class="text-start"
        :class="[
          this.message.status == 200 ? ' text-green-700' : ' text-red-700',
        ]"
      >
        {{ this.message.text }}
      </p>
      <button
        v-if="!loading"
        type="submit"
        class="w-full border h-[49px] rounded-lg font-bold bg-[#2A84EF] text-white roboto-light"
      >
        Sign up
      </button>
      <button
        v-else
        class="w-full border h-[49px] rounded-lg font-bold bg-[#2A84EF] text-white roboto-light"
      >
        Loading...
      </button>
      <div class="flex flex-col gap-y-4 roboto-light">
        <div class="flex items-center p-2">
          <span class="flex-grow border-b border-gray-300"></span>
          <p class="text-sm text-gray-500 mx-3">Already have an account?</p>
          <span class="flex-grow border-b border-gray-300"></span>
        </div>

        <router-link to="/">
          <button
            class="bg-black text-white rounded-md py-1 text-center roboto-light h-[49px] w-full"
          >
            Sign in here
          </button>
        </router-link>
      </div>
    </form>

    <!-- <div
      v-if="message.text != ''"
      class="fixed top-2 right-2 w-[340px] p-2 bg-gray-100 min-h-[60px] rounded-md flex items-center justify-center text-sm capitalize"
      :class="
        this.message.status != 200
          ? 'border border-red-400 bg-red-300'
          : 'border border-green-400 bg-green-300'
      "
    >
      {{ message.text }}
    </div> -->
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/FooterVIew.vue";
import axios from "axios";
import CC from "@/services/coutryCode.js";

export default {
  name: "LoginPage",
  data() {
    return {
      fullname: "",
      email: "",
      password: "",
      mobile: "",
      confirmPassword: "",
      message: {
        text: "",
        status: "",
      },
      loading: false,
      c_code: [],
      country_code: "+91",
      is_country_code: false,
      country_flag: "in",
    };
  },
  components: {
    Footer,
  },
  created() {
    // console.log(CC.countryCode());
    this.c_code = CC.countryCode();
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  watch: {},
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    toHome() {
      this.$router.push({ path: "/" });
    },
    handleClickOutside(event) {
      if (
        this.is_country_code &&
        !this.$refs.country_ref.contains(event.target) &&
        !this.$refs.dropdownContent.contains(event.target)
      ) {
        this.is_country_code = false;
      }
    },
    signUp() {
      this.loading = true;
      const userData = {
        first_name: this.fullname,
        password: this.password,
        email: this.email,
        last_name: "",
        country_code: this.country_code,
        phone: this.mobile,
      };
      if (this.password == this.confirmPassword) {
        axios
          .post(`${process.env.VUE_APP_API}/register/`, userData)
          .then((response) => {
            console.log(response);
            if (response.data.status == 200) {
              this.message.text =
                "Welcome to LIVEYfy! Please Verify Your Email";
              this.message.status = 200;
              this.loading = true;
              setTimeout(() => {
                this.$router.push({ path: "/" });
                this.message.text = "";
                this.loading = false;
                this.message.status = "";
              }, 5000);
            } else {
              this.message.text = response.data.message;
              this.message.status = 400;
              this.loading = true;
              setTimeout(() => {
                this.message.text = "";
                this.loading = false;
                this.message.status = "";
              }, 4000);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        this.message.text = "Password dosen't match";
        this.message.status = 400;
        setTimeout(() => {
          this.message.text = "";
          this.loading = false;
        }, 3000);
      }
    },
  },
};
</script>

<style scoped></style>
